<template>
  <b-form
    @submit.prevent="submitForm"
    class="d-flex align-items-end"
    :class="{ 'align-items-center': description || centered }"
  >
    <slot>
      <!-- Default slot content -->
      <b-form-group
        :label="label"
        :label-for="label"
        class="flex-grow-1"
        :description="description"
      >
        <b-form-input
          :id="label"
          v-model="changedValue"
          :disabled="disabled"
        ></b-form-input>
      </b-form-group>
    </slot>
    <b-button
      v-if="!disabled && !hideOk"
      :class="{
        'mb-2': description || centered,
        'mb-3': !(description || centered),
      }"
      variant="primary"
      :disabled="!changed"
      type="submit"
      v-b-tooltip.hover
      :title="$t('properties.usermenu.save')"
      ><i class="fas fa-check"></i
    ></b-button>
    <b-button
      v-if="!disabled && showCancel"
      :class="{
        'mb-2': description || centered,
        'mb-3': !(description || centered),
      }"
      variant="danger"
      :disabled="!changed"
      @click="reset(value)"
      v-b-tooltip.hover
      :title="$t('properties.usermenu.cancel')"
      ><i class="fas fa-times"></i
    ></b-button>
    <b-button
      v-if="showDelete"
      :class="{
        'mb-2': description || centered,
        'mb-3': !(description || centered),
      }"
      variant="danger"
      @click="remove"
      v-b-tooltip.hover
      :title="$t('properties.usermenu.delete')"
      ><i class="fas fa-trash"></i
    ></b-button>
  </b-form>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    hideOk: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    changed() {
      return this.value !== this.changedValue;
    },
  },
  data() {
    return {
      changedValue: this.value,
    };
  },
  methods: {
    reset(value) {
      this.changedValue = value;
    },
    submitForm() {
      this.$emit("ok", this.changedValue);
    },
    remove() {
      this.$emit("delete");
    },
  },
  watch: {
    value(newValue) {
      // if value changes, update the model of the input field accordingly
      this.reset(newValue);
    },
  },
};
</script>

<style>
</style>