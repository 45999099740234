<template>
  <b-container>
    <b-overlay :show="loading" spinner-variant="primary" no-wrap />
    <div class="pt-2">
      <b-card bg-variant="secondary" class="mt-1 shadow" v-if="user">
        <div class="text-light">
          <div class="d-flex">
            <b-avatar variant="primary" :text="initials" size="4rem" />
            <div class="ml-3 d-flex flex-column">
              <h3>
                {{ getAttribute(user, "firstName") || "-" }}
                {{ getAttribute(user, "lastName") || "-" }}
              </h3>
              <p class="">{{ username }}</p>
            </div>
          </div>
          <hr class="mx-4" />
          <div class="d-flex flex-column text-white-50">
            <span
              >{{ $t("user.lastLogin") }}:
              {{
                getAttribute(user, "lastLogin") == ""
                  ? $t("user.never")
                  : $d(
                      new Date(getAttribute(user, "lastLogin")),
                      "datetime-short"
                    )
              }}</span
            >
            <span v-if="getAttribute(user, 'created') != ''"
              >{{ $t("user.created") }}:
              {{
                $d(new Date(getAttribute(user, "created")), "datetime-short")
              }}</span
            >
          </div>
        </div>
      </b-card>
    </div>
    <div class="d-flex flex-md-row flex-column">
      <!-- User Data -->
      <b-card class="mt-2 mr-md-2 shadow flex-fill" v-if="showUser">
        <h2 class="card-title">
          <i class="fas fa-user-edit mr-2"></i>{{ $t("user.userData") }}
        </h2>
        <b-form @submit.prevent="submitUserData" v-if="showUser">
          <b-form-group
            :label="$t('user.username')"
            label-for="username"
            :description="$t('user.usernameCantBeChanged')"
          >
            <b-form-input
              id="username"
              v-model="userFormData.name"
              type="email"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group :label="$t('user.firstName')" label-for="firstName">
            <b-form-input
              id="firstName"
              v-model="userFormData.firstName"
              type="text"
              autocomplete="off"
            ></b-form-input>
          </b-form-group>

          <b-form-group :label="$t('user.lastName')" label-for="lastName">
            <b-form-input
              id="lastName"
              v-model="userFormData.lastName"
              type="text"
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
          <div class="d-flex justify-content-end">
            <b-button type="submit" variant="primary"
              ><i class="fas fa-save mr-2"></i
              >{{ userFormButtonText }}</b-button
            >
          </div>
        </b-form>
      </b-card>
      <!-- Reset Password -->
      <b-card class="mt-2 shadow flex-fill" v-if="showUser">
        <h2 class="card-title">
          <i class="fas fa-key mr-2"></i>{{ $t("user.resetPassword") }}
        </h2>
        <b-form @submit.prevent="submitPasswordChange" v-if="showUser">
          <b-form-group :label="$t('user.newPassword')" label-for="username">
            <b-form-input
              id="password1"
              v-model="resetPassword.password1"
              type="password"
              autocomplete="off"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="$t('user.confirmPassword')"
            label-for="confirmPassword"
          >
            <b-form-input
              id="confirmPassword"
              v-model="resetPassword.password2"
              type="password"
              :state="validatePassword"
              autocomplete="off"
            ></b-form-input>
            <b-form-invalid-feedback :state="validatePassword">
              <i class="fas fa-exclamation-circle mr-2"></i
              >{{ $t("user.passwordMustMatch") }}
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="d-flex justify-content-end">
            <b-button
              type="submit"
              variant="primary"
              :disabled="!validatePassword"
              ><i class="fas fa-lock mr-2"></i
              >{{ $t("user.resetPassword") }}</b-button
            >
          </div>
        </b-form>
      </b-card>
    </div>
    <div class="d-flex flex-md-row flex-column">
      <!-- Roles -->
      <b-card class="mt-2 mr-md-2 shadow" v-if="showUser">
        <h2 class="card-title">
          <i class="fas fa-user-tag mr-2"></i>{{ $t("user.roles") }}
        </h2>
        <div
          class="d-flex justify-content-end align-items-center mb-4"
          v-if="allRoles != null"
        >
          <b-form inline @submit.prevent="addRole">
            <vue-single-select
              class="mr-2"
              id="roleSelect"
              input-id="roleSelector"
              v-model="selectedRole"
              :options="
                allRoles.filter((role) => {
                  return !this.showUser.roles.includes(role);
                })
              "
              :placeholder="$t('user.enterRoleName')"
            ></vue-single-select>

            <b-button variant="outline-secondary" pill type="submit"
              ><i class="fas fa-plus mr-2"></i
              >{{ $t("permissions.addRole") }}</b-button
            >
          </b-form>
        </div>

        <b-alert variant="primary" :show="showUser.roles.length == 0">{{
          $t("user.noRoles")
        }}</b-alert>
        <b-list-group>
          <b-list-group-item
            v-for="role in showUser.roles"
            :key="role"
            class="d-flex justify-content-between align-items-center"
          >
            <router-link :to="{ name: 'Role', params: { name: role } }">{{
              role
            }}</router-link>
            <div class="menu-buttons">
              <b-button pill variant="outline-danger" @click="removeRole(role)"
                ><i class="fas fa-trash"></i
              ></b-button>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-card>
      <!-- Permissions -->
      <b-card class="mt-2 shadow">
        <h2 class="card-title">
          <i class="fas fa-tags mr-2"></i>{{ $t("user.permissions") }}
        </h2>
        <p class="text-muted">
          <i class="fas fa-info-circle mr-2"></i>{{ $t("user.permissionNote") }}
        </p>
        <permission-view
          v-bind:permissions="showPermissions"
          @save="savePermissions"
        />
      </b-card>
    </div>
    <!-- Delete User -->
    <b-card class="mt-2 shadow flex-fill" v-if="showUser">
      <h2 class="card-title">
        <i class="fas fa-trash mr-2"></i>{{ $t("user.deleteUser") }}
      </h2>
      <p class="text-muted">
        <i class="fas fa-exclamation-triangle mr-2"></i
        >{{ $t("user.deleteWarning") }}
      </p>
      <b-form @submit.prevent="deleteUser" v-if="showUser">
        <b-form-group :label="$t('user.deleteUsername')" label-for="username">
          <b-form-input
            id="usernameValidation"
            v-model="usernameValidation"
            type="text"
            :state="validateUsername"
            autocomplete="off"
          ></b-form-input>
          <b-form-invalid-feedback :state="validateUsername">
            <i class="fas fa-exclamation-circle mr-2"></i
            >{{ $t("user.invalidUsername", { username: this.username }) }}
          </b-form-invalid-feedback>
        </b-form-group>

        <div class="d-flex justify-content-end">
          <b-button type="submit" variant="danger" :disabled="!validateUsername"
            ><i class="fas fa-lock mr-2"></i
            >{{ $t("user.deleteUser") }}</b-button
          >
        </div>
      </b-form>
    </b-card>

    <b-card class="mt-2" v-if="showUser">
      <h2 class="card-title d-flex justify-content-between">
        <span>
          <i class="fas fa-terminal mr-2"></i>{{ $t("user.properties") }}
        </span>

        <i class="fas fa-chevron-down" v-b-toggle.user-attributes-collapse></i>
      </h2>

      <p class="text-muted">
        <i class="fas fa-exclamation-triangle mr-2"></i
        >{{ $t("user.propertiesWarning") }}
      </p>
      <b-collapse id="user-attributes-collapse">
        <b-card>
          <b-card-text class="d-flex justify-content-end">
            <b-button v-b-modal.attribute-name-input><i class="fas fa-plus"></i> {{$t('user.newAttribute')}}</b-button>
          </b-card-text>
          <b-card-text>
            <property-editor
              v-for="att in showUser.attributes"
              :key="att.name"
              :label="att.name"
              :value="att.value"
              showDelete
              :showCancel="false"
              @ok="saveAttribute(att.name, $event)"
              @delete="deleteAttribute(att.name)"
            />
          </b-card-text>
        </b-card>
      </b-collapse>
    </b-card>


    <text-input-modal
        modalId="attribute-name-input"
        :title="$t('user.addUserProperty')"
        :label="$t('properties.enterTitle')"
        @ok="saveAttribute($event, '')"
      />
  </b-container>
</template>

<script>
import axios from "axios";
import { /*mapGetters,*/ mapActions } from "vuex";
import PermissionView from "../components/PermissionView.vue";
import PropertyEditor from "../components/PropertyEditor.vue";
import Swal from "sweetalert2";
import VueSingleSelect from "vue-single-select";
import TextInputModal from "@/components/TextInputModal.vue";

export default {
  components: { PermissionView, VueSingleSelect, PropertyEditor, TextInputModal },
  name: "User",
  props: { username: String },
  data() {
    return {
      loading: false,
      user: null,
      showUser: null,
      showPermissions: [],
      userFormData: {},
      userFormButtonText: this.$t("user.save"),
      resetPassword: {
        password1: "",
        password2: "",
      },
      allRoles: [],
      selectedRole: null,
      usernameValidation: "",
    };
  },
  computed: {
    initials() {
      let fn = this.getAttribute(this.user, "firstName");
      let ln = this.getAttribute(this.user, "lastName");
      if (fn && ln) {
        return fn.charAt(0) + ln.charAt(0);
      } else {
        return this.username.charAt(0);
      }
    },
    validatePassword() {
      if (
        this.resetPassword.password1 == "" ||
        this.resetPassword.password1 == ""
      )
        return null;
      return this.resetPassword.password1 === this.resetPassword.password2;
    },
    validateUsername() {
      if (this.usernameValidation == "") return null;
      return this.usernameValidation === this.username;
    },
  },
  methods: {
    ...mapActions("alerts", ["fireAlert"]),
    loadData() {
      this.loading = true;
      axios
        .get(
          `https://remote.eospowersolutions.com/my-devices/api/users/${encodeURI(
            this.username
          )}`
        )
        .then((res) => {
          this.user = res.data;
          //load all available roles from server for vue-single-select options
          axios
            .get(`/my-devices/api/roles`)
            .then((res) => {
              this.allRoles = res.data.map((r) => {
                return r["name"];
              });
            })
            .catch((err) => {
              this.allRoles = null;
              console.error(err);
              this.fireAlert([
                this.$t("Error"),
                this.$t("user.errorLoadingRoles"),
                "danger",
              ]);
            });
        })
        .catch(() => {
          this.user = null;
          this.fireAlert([
                this.$t("Error"),
                this.$t("user.errorLoadingData"),
                "danger",
              ]);
        })
        .finally(() => {
          this.loading = false;
          this.showUser = this.user;
          if (this.user) {
            this.showPermissions = this.user.permissions;
            this.userFormData.name = this.user["name"];
            this.userFormData.firstName = this.getAttribute(
              this.user,
              "firstName"
            );
            this.userFormData.lastName = this.getAttribute(
              this.user,
              "lastName"
            );
          }
        });
    },
    getAttribute(user, attName) {
      let attValue = "";
      user["attributes"].forEach((a) => {
        if (a["name"] === attName) {
          attValue = a["value"];
          return;
        }
      });
      return attValue;
    },
    submitUserData() {
      //submits the userdata to server
      this.userFormButtonText = this.$t("user.saving") + "...";
      let attributes = ["firstName", "lastName"];
      attributes.forEach((attribute) => {
        axios
          .post(
            `https://remote.eospowersolutions.com/my-devices/api/users/${encodeURI(
              this.username
            )}/attributes`,
            JSON.stringify({
              name: attribute,
              value: this.userFormData[attribute],
            })
          )
          .then(() => {
            this.loadData();
            this.userFormButtonText = "OK";
            setTimeout(
              (vm) => {
                vm.userFormButtonText = this.$t("user.save");
              },
              3000,
              this
            );
          })
          .catch((err) => {
            console.error(err);
          });
      });
    },
    savePermissions(eventData) {
      // console.log("saved permissions: ", JSON.stringify(eventData));
      axios
        .put(
          `https://remote.eospowersolutions.com/my-devices/api/users/${encodeURI(
            this.username
          )}/permissions`,
          JSON.stringify(eventData)
        )
        .then(() => {
          this.fireAlert([
            this.$t("Success"),
            this.$t("user.permissionsChanged"),
            "success",
          ]);
          this.loadData();
        })
        .catch(() => {
          this.fireAlert([
            this.$t("Error"),
            this.$t("user.permissionsNotChanged"),
            "error",
          ]);
        });
    },
    submitPasswordChange() {
      if (!this.validatePassword) return;

      axios
        .patch(
          `https://remote.eospowersolutions.com/my-devices/api/users/${encodeURI(
            this.username
          )}`,
          JSON.stringify({ password: this.resetPassword.password1 })
        )
        .then(() => {
          this.fireAlert([
            this.$t("Success"),
            this.$t("user.passwordChanged"),
            "success",
          ]);
          this.resetPassword.password1 = "";
          this.resetPassword.password2 = "";
        })
        .catch(() => {
          this.fireAlert([
            this.$t("Error"),
            this.$t("user.passwordNotChanged"),
            "error",
          ]);
        });
    },
    addRole() {
      //adds this.selectedRole to current user
      if (!this.selectedRole) return;
      axios
        .post(
          `/my-devices/api/users/${encodeURI(this.username)}/roles`,
          JSON.stringify([this.selectedRole])
        )
        .then(() => {
          this.fireAlert([
            this.$t("Success"),
            this.$t("user.addedRole"),
            "success",
          ]);
          this.showUser.roles.push(this.selectedRole);
          this.selectedRole = null;
        })
        .catch((err) => {
          console.error(err);
          this.fireAlert([
            this.$t("Error"),
            this.$t("user.notAddedRole"),
            "danger",
          ]);
        });
    },
    removeRole(role) {
      //removes a given role from current user
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger m-1",
          cancelButton: "btn btn-primary m-1",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: this.$t("user.removeRole", { role: role }),
          text: this.$t("user.roleRemoveNote", {
            role: role,
            username: this.username,
          }),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("user.yesRemoveRole"),
          cancelButtonText: this.$t("user.noCancel"),
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .delete(
                `/my-devices/api/users/${encodeURI(
                  this.username
                )}/roles/${encodeURI(role)}`
              )
              .then(() => {
                this.fireAlert([
                  this.$t("Success"),
                  this.$t("role.RoleDeleted"),
                  "success",
                ]);
                this.showUser.roles.splice(
                  this.showUser.roles.indexOf(role),
                  1
                );
              })
              .catch((err) => {
                this.fireAlert([
                  this.$t("Error"),
                  this.$t("role.RoleNotDeleted"),
                  "danger",
                ]);
                console.error(err);
              });
          }
        });
    },
    /**
     * Deletes the current user.
     */
    deleteUser() {
      axios
        .delete(`/my-devices/api/users/${encodeURI(this.username)}`)
        .then(() => {
          this.fireAlert([
            this.$t("Success"),
            this.$t("user.UserNameDeleted", { username: this.username }),
            "success",
          ]);
          this.$router.push({ name: "Users" });
        })
        .catch((err) => {
          console.error(err);
          this.fireAlert([
            this.$t("Error"),
            this.$t("user.UserNameNotDeleted", { username: this.username }),
            "danger",
          ]);
        });
    },
    saveAttribute(name, value) {
      //submits the user attributes to server
      axios
        .post(
          `https://remote.eospowersolutions.com/my-devices/api/users/${encodeURI(
            this.username
          )}/attributes`,
          JSON.stringify({
            name: name,
            value: value,
          })
        )
        .then(() => {
          this.loadData();
          this.fireAlert([
            this.$t("Success"),
            this.$t("properties.done"),
            "success",
          ]);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    deleteAttribute(name) {
      //submits the user attributes to server
      axios
        .delete(
          `https://remote.eospowersolutions.com/my-devices/api/users/${encodeURI(
            this.username
          )}/attributes/${name}`
        )
        .then(() => {
          this.loadData();
          this.fireAlert([
            this.$t("Success"),
            this.$t("properties.done"),
            "success",
          ]);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
a {
  color: black;
}
</style>