var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"d-flex align-items-end",class:{ 'align-items-center': _vm.description || _vm.centered },on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_vm._t("default",[_c('b-form-group',{staticClass:"flex-grow-1",attrs:{"label":_vm.label,"label-for":_vm.label,"description":_vm.description}},[_c('b-form-input',{attrs:{"id":_vm.label,"disabled":_vm.disabled},model:{value:(_vm.changedValue),callback:function ($$v) {_vm.changedValue=$$v},expression:"changedValue"}})],1)]),(!_vm.disabled && !_vm.hideOk)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:{
      'mb-2': _vm.description || _vm.centered,
      'mb-3': !(_vm.description || _vm.centered),
    },attrs:{"variant":"primary","disabled":!_vm.changed,"type":"submit","title":_vm.$t('properties.usermenu.save')}},[_c('i',{staticClass:"fas fa-check"})]):_vm._e(),(!_vm.disabled && _vm.showCancel)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:{
      'mb-2': _vm.description || _vm.centered,
      'mb-3': !(_vm.description || _vm.centered),
    },attrs:{"variant":"danger","disabled":!_vm.changed,"title":_vm.$t('properties.usermenu.cancel')},on:{"click":function($event){return _vm.reset(_vm.value)}}},[_c('i',{staticClass:"fas fa-times"})]):_vm._e(),(_vm.showDelete)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:{
      'mb-2': _vm.description || _vm.centered,
      'mb-3': !(_vm.description || _vm.centered),
    },attrs:{"variant":"danger","title":_vm.$t('properties.usermenu.delete')},on:{"click":_vm.remove}},[_c('i',{staticClass:"fas fa-trash"})]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }